import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { UUID } from 'src/utils/types';
import UploadModal from 'src/components/UploadModal';
import { useQv3Context } from 'src/QuestionnairesV3/hooks';
import { FieldType, FileSchema, InputProps } from 'src/QuestionnairesV3/types';
import Files from './Files';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { generateDummyEvent } from 'src/QuestionnairesV3/utils';

export default function FileUpload(props: Readonly<InputProps>) {
  const { onChange, value, fieldId } = props;
  const { t } = useTranslation();
  const { questionnaireAnswerId, queries } = useQv3Context();
  const field = queries.fields._dataMap[fieldId];

  const [uploadOpen, setUploadOpen] = useState(false);
  const [confirmFileDelete, setConfirmFileDelete] = useState<
    UUID | undefined
  >();

  const handleDeleteClick = () => {
    const newValue = Array.isArray(value)
      ? (value as FileSchema[]).filter((f) => f.id !== confirmFileDelete)
      : null;
    const dummyEvent = generateDummyEvent(null);
    onChange?.(newValue, dummyEvent, true);
    setConfirmFileDelete(undefined);
  };

  const handleFileUploaded = (file: FileSchema) => {
    const dummyEvent = generateDummyEvent(file);
    const newValue =
      field.type === FieldType.multifile
        ? [...(value as FileSchema[]), file]
        : file;
    onChange?.(newValue, dummyEvent, true); // true stands for instantSave
    setUploadOpen(false);
  };

  return (
    <>
      {value && (
        <Files
          value={value as FileSchema | FileSchema[]}
          onDeleteClick={(fileId) => setConfirmFileDelete(fileId)}
        />
      )}

      {(field.type === FieldType.multifile || value === null) && (
        <Button variant="outlined" onClick={() => setUploadOpen(true)}>
          {t('esg:attach')}
        </Button>
      )}

      <ConfirmationModal
        open={!!confirmFileDelete}
        onClose={() => setConfirmFileDelete(undefined)}
        onFalse={() => setConfirmFileDelete(undefined)}
        onTrue={handleDeleteClick}
      />

      <UploadModal
        url={`api-questionnaires/questionnaire-attachments?questionnaire_answer_id=${questionnaireAnswerId}`}
        open={uploadOpen}
        onClose={() => setUploadOpen(false)}
        onSuccess={handleFileUploaded}
      />
    </>
  );
}
