import React, { ChangeEvent, MouseEvent } from 'react';
import { Box, Chip, SelectChangeEvent, Typography } from '@mui/material';

import { UUID } from 'src/utils/types';
import { StyledFlex } from 'src/components/StyledFlex';
import { useFieldAnswers, useQv3Context } from '../hooks';
import FieldInput from './Inputs';
import Buttons from './Buttons';
import { FieldValueSchema } from '../types';
import FieldAnswerComments from './FieldAnswerComments';
import EsgReferences from './References/EsgReferences';
import { useFieldAnswerValues } from './FieldAnswerValues';
import { useFieldVisibilitySubscription } from './FieldAnswerValues/hooks';
import { useFieldAnswerLocal } from './hooks';
import { updateLocalValue } from './FieldAnswerValues/utils';

export interface Props {
  questionAnswerId: number;
  fieldId: UUID;
  options?: {
    alwaysShowButtons: boolean;
  };
}

// ConditionalField is a wrapper around the generic <Field /> component.
// It determines whether the field should be displayed based on dynamic
// dependencies on other fields' values.
// Here's what it does:
// - Looks up all fields that the current field depends on (directly or indirectly).
// - Subscribes to value changes of those dependencies using `fieldChange$` (RxJS).
// - When a dependent field changes, it evaluates visibility logic via `checkVisibilityLogic(...)`.
// - Updates internal visibility state using Zustand (`useValuesSignals`).
// - Conditionally renders <Field /> only if the field is visible.
export default function ConditionalField(props: Readonly<Props>) {
  const { fieldId, questionAnswerId } = props;
  const { isVisible } = useFieldAnswerLocal(questionAnswerId, fieldId);
  useFieldVisibilitySubscription(questionAnswerId, fieldId);

  if (!isVisible) return null;
  return <Field {...props} />;
}

// Field is the main component that renders the input field for a given question_answer.
// It also handles the logic for saving, deleting, and updating the field value.
// It uses Zustand to manage local state for the field value and initial value.
const Field = (props: Readonly<Props>) => {
  const { questionAnswerId, fieldId, options } = props;
  const { alwaysShowButtons = false } = options || {};
  const { options: qv3Options, queries } = useQv3Context();
  const { withFieldReferences, withFieldComments, withEsgReferences } =
    qv3Options;

  // Get the fieldAnswerLocal data from globalState
  const { fieldAnswerLocalId, value, initialValue, isModified } =
    useFieldAnswerLocal(questionAnswerId, fieldId);

  // Get the field definition by ID
  const field = queries.fields._dataMap[fieldId];

  // Fetch all answers for the current question_answer
  const fieldAnswers = useFieldAnswers({
    question_answer_id: questionAnswerId,
  });

  // Get the specific answer for this field, if it exists
  const fieldAnswer = React.useMemo(
    () => fieldAnswers._data.find((fa) => fa.field_id === field.id),
    [fieldAnswers._data, field.id]
  );
  const isNew = !fieldAnswer?.id;

  // Update value locally and broadcast to global signal/event system
  const updateValue = (_value: FieldValueSchema) =>
    updateLocalValue(questionAnswerId, fieldId, _value);

  // Button handlers
  const handleCancelClick = () => updateValue(initialValue);

  const handleDeleteClick = () =>
    fieldAnswer &&
    fieldAnswers._delete.mutateAsync(fieldAnswer.id).then(() => {
      updateValue(null);
      useFieldAnswerValues
        .getState()
        .updateInitialValue(fieldAnswerLocalId, null);
    });

  const handleSuccess = (_value: FieldValueSchema) =>
    useFieldAnswerValues
      .getState()
      .updateInitialValue(fieldAnswerLocalId, _value);

  const handleSaveClick = () =>
    !isNew
      ? fieldAnswer &&
        fieldAnswers.update
          .mutateAsync({ id: fieldAnswer.id, value })
          .then(() => handleSuccess(value))
      : fieldAnswers.create
          .mutateAsync({
            field_id: field.id,
            question_answer_id: questionAnswerId,
            value,
          })
          .then(() => handleSuccess(value));

  const handleInstantSave = (_value: FieldValueSchema) =>
    !isNew
      ? fieldAnswer &&
        fieldAnswers.update
          .mutateAsync({ id: fieldAnswer.id, value: _value })
          .then(() => handleSuccess(_value))
      : fieldAnswers.create
          .mutateAsync({
            field_id: field.id,
            question_answer_id: questionAnswerId,
            value: _value,
          })
          .then(() => handleSuccess(_value));

  // Handles input changes – triggers either instant save or local update
  const handleOnChange = (
    _value: FieldValueSchema,
    _: ChangeEvent | MouseEvent | SelectChangeEvent,
    instantSave?: boolean
  ) => {
    updateValue(_value);
    if (instantSave) handleInstantSave(_value);
  };

  // Prevent rendering if field metadata is missing
  if (!field) return null;

  return (
    <Box m={0} mb={5}>
      <StyledFlex>
        <Typography variant="body1" fontWeight={600} color="#38414f">
          {field.title}
        </Typography>
        <StyledFlex justifyContent="end">
          {withFieldReferences && <Chip label={`Ref: ${field.reference_id}`} />}
          {withFieldComments && fieldAnswer && (
            <FieldAnswerComments fieldAnswerId={fieldAnswer.id} />
          )}
        </StyledFlex>
      </StyledFlex>
      <br />
      <FieldInput
        fieldId={fieldId}
        value={value}
        onChange={handleOnChange}
        error={fieldAnswers._errors[field.id]}
      />
      {(isModified || alwaysShowButtons) && (
        <Buttons
          fieldAnswer={fieldAnswer}
          onCancelClick={handleCancelClick}
          onDeleteClick={handleDeleteClick}
          onSaveClick={handleSaveClick}
        />
      )}
      {withEsgReferences && <EsgReferences fieldId={fieldId} />}
    </Box>
  );
};
