import React from 'react';
import { InputProps } from '../../types';

import NumberField from 'src/components/NumberField';
import { useQv3Context } from 'src/QuestionnairesV3/hooks';

export default function DecimalTextField(props: Readonly<InputProps>) {
  const { value, onChange, error, fieldId } = props;
  const { queries } = useQv3Context();
  const field = queries.fields._dataMap[fieldId];
  return (
    <NumberField
      fullWidth
      value={value ?? ''}
      label={field.label}
      onChange={(value, event) => onChange?.(value, event)}
      error={!!error}
      helperText={error}
      // disabled={disabled}
      placeholder={field.placeholder}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: value ? 'background.default' : 'inherit',
        },
      }}
    />
  );
}
