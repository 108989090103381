import { Dialog, DialogProps } from '@mui/material';
import React from 'react';
import Field from 'src/QuestionnairesV3/Fields/Field';
import { UUID } from 'src/utils/types';

interface Props extends DialogProps {
  questionAnswerId?: number;
  fieldId?: UUID;
}

export default function FieldAnswerEditDialog(props: Readonly<Props>) {
  const { questionAnswerId, fieldId, ..._props } = props;
  if (!questionAnswerId || !fieldId) return null;
  return (
    <Dialog {..._props} maxWidth="md">
      <Field
        questionAnswerId={questionAnswerId}
        fieldId={fieldId}
        options={{ alwaysShowButtons: true }}
      />
    </Dialog>
  );
}
