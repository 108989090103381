import React from 'react';
import { DecimalWithUnitSchema, InputProps } from '../../types';

import { StyledFlex } from 'src/components/StyledFlex';
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useLanguage } from 'src/common/hooks';
import { useQv3Context, useQv3Enums } from 'src/QuestionnairesV3/hooks';

export default function DecimalWithUnit(props: Readonly<InputProps>) {
  const { error, value: objValue, onChange, fieldId } = props;
  const { t } = useLanguage();
  const { queries } = useQv3Context();
  const field = queries.fields._dataMap[fieldId];
  // will be moved to useQv3 when working on enums PR
  const { qv3Enum } = useQv3Enums(field.enum_id);

  const { value, unit } = (objValue as DecimalWithUnitSchema) || {};
  const unitLabel = t('questionnaireV3.fieldTypes.decimal_with_unit.unitLabel');

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange?.({ value: e.target.value, unit: unit ?? qv3Enum.default }, e);

  const handleUnitChange = (e: SelectChangeEvent<string>) =>
    onChange?.({ unit: e.target.value, value }, e);

  return (
    <StyledFlex sx={{ gap: '5px', alignItems: 'flex-start' }}>
      <TextField
        fullWidth
        multiline
        value={value ?? ''}
        label={field.label}
        onChange={handleValueChange}
        error={!!error}
        helperText={error}
        // disabled={disabled}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: value ? '#e0e0e0' : 'inherit',
          },
        }}
      />
      <FormControl sx={{ minWidth: '50%', maxWidth: '50%' }}>
        <InputLabel id={field.key}>{unitLabel}</InputLabel>
        <Select
          sx={{
            height: '57px',
            backgroundColor: unit ? '#e0e0e0' : 'inherit',
          }}
          labelId={field.key}
          label={unitLabel}
          value={unit ?? qv3Enum.default ?? ''}
          onChange={handleUnitChange}
          error={!!error}
        >
          {qv3Enum.options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StyledFlex>
  );
}
