import React from 'react';
import { Button, Collapse } from '@mui/material';

import { UUID } from 'src/utils/types';
import { useLanguage } from 'src/common/hooks';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { useQuestionAnswers, useQv3Context } from '../hooks';
import Field from './Field';
import { buttonIdPrefix } from './Buttons';
import QuestionAnswerHeader from './QuestionAnswerHeader';
import { useIsQuestionAnswerModified } from './hooks';
import { StyledQuestionPanelContent } from '../components/QuestionPanel';
import PopulateFieldAnswersProvider from './FieldAnswerValues/Provider';

interface Props {
  questionId: UUID;
  questionAnswerId: number;
}

// this PreRender component is used to populate initial values for all fields in the current question
export default function Fields(props: Readonly<Props>) {
  return (
    <PopulateFieldAnswersProvider {...props}>
      <Component {...props} />
    </PopulateFieldAnswersProvider>
  );
}

// this Field Compoentn is wrapped in a PreRender component to populate initial values
// PreRender Component (on the bottom of this file) is the default export
function Component(props: Readonly<Props>) {
  const { questionId, questionAnswerId } = props;
  const { t } = useLanguage({ keyPrefix: 'questionnaireV3.questionAnswer' });
  const { queries } = useQv3Context();
  const questionAnswers = useQuestionAnswers({}, { enabled: false });
  const isModified = useIsQuestionAnswerModified(questionAnswerId);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);

  const fields = queries.fields._data.filter(
    (f) => f.question_id === questionId
  );

  // Collapse animation
  const [collapsed, setCollapsed] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => setCollapsed(false), 100);
  }, []);

  if (queries.fields.isLoading) return null;
  if (!questionId) return null;

  // save all modified fields in the current questionAnswer
  const handleSaveClick = () => {
    document
      .querySelectorAll(`button[id^="${buttonIdPrefix}"]`)
      .forEach((button) => (button as HTMLButtonElement).click());
  };

  const handleDeleteQuestionAnswer = () => {
    setCollapsed(true);
    setTimeout(() => questionAnswers._delete.mutate(questionAnswerId), 300);
  };

  return (
    <StyledQuestionPanelContent>
      <Collapse in={!collapsed}>
        <QuestionAnswerHeader
          questionAnswerId={questionAnswerId}
          onDeleteClick={() => setDeleteConfirmationOpen(true)}
        />
        {fields.map((field) => (
          <Field
            key={`${field.id}::${questionAnswerId}`}
            questionAnswerId={questionAnswerId}
            fieldId={field.id}
          />
        ))}
        {isModified && (
          <Button
            fullWidth
            variant="contained"
            onClick={handleSaveClick}
            sx={{ mb: '24px' }}
          >
            {t('saveButton')}
          </Button>
        )}
        <ConfirmationModal
          open={deleteConfirmationOpen}
          titleKey={t('confirmDeleteTitle')}
          textKey={t('confirmDeleteText')}
          onFalse={() => setDeleteConfirmationOpen(false)}
          onClose={() => setDeleteConfirmationOpen(false)}
          onTrue={handleDeleteQuestionAnswer}
        />
      </Collapse>
    </StyledQuestionPanelContent>
  );
}
