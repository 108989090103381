/* eslint-disable no-unused-vars */
import { create } from 'zustand';

import {
  FieldAnswerLocalId,
  FieldValueSchema,
} from 'src/QuestionnairesV3/types';
import { UUID } from 'src/utils/types';

// Zustand store for managing global fieldAnswers values through localId
// all values are stored in the global state by local Id key
// localId is a UUID generated for each field answer, can be retrieved by useFieldAnswerLocal hook

// This store tracks:
// - `fieldAnswersMap`: map of field answers for the current question answer by field ID
// - `values`: current values of all fields
// - `initialValues`: initial values of all fields
// - `visibility`: visibility flags for conditional field rendering

export type FieldAnswerValues = {
  fieldAnswersMap: { [key: number]: { [key: UUID]: FieldAnswerLocalId } };
  setFieldAnswersMap: (
    questionAnswerId: number,
    fieldAnswersMap: { [key: UUID]: FieldAnswerLocalId }
  ) => void;

  initialValues: Record<FieldAnswerLocalId, FieldValueSchema>;
  setInitialValues: (
    values: Record<FieldAnswerLocalId, FieldValueSchema>
  ) => void;
  updateInitialValue: (
    fieldAnswerLocalId: FieldAnswerLocalId,
    value: FieldValueSchema
  ) => void;

  values: Record<FieldAnswerLocalId, FieldValueSchema>;
  updateValue: (
    fieldAnswerLocalId: FieldAnswerLocalId,
    value: FieldValueSchema
  ) => void;

  visibility: Record<FieldAnswerLocalId, boolean>;
  updateVisibility: (
    fieldAnswerLocalId: FieldAnswerLocalId,
    isVisible: boolean
  ) => void;
};

export const useFieldAnswerValues = create<FieldAnswerValues>((set) => ({
  fieldAnswersMap: {},
  setFieldAnswersMap: (questionAnswerId, fieldAnswersMap) => {
    set((state) => {
      return {
        fieldAnswersMap: {
          ...state.fieldAnswersMap,
          [questionAnswerId]: fieldAnswersMap,
        },
      };
    });
  },
  initialValues: {},
  setInitialValues: (values) =>
    set((state) => {
      return {
        initialValues: { ...state.initialValues, ...values },
        values: { ...state.values, ...values },
      };
    }),
  updateInitialValue: (fieldAnswerLocalId, value) =>
    set((state) => {
      return {
        initialValues: {
          ...state.initialValues,
          [fieldAnswerLocalId]: value,
        },
      };
    }),
  values: {},
  updateValue: (fieldAnswerLocalId, value) =>
    set((state) => {
      return {
        values: {
          ...state.values,
          [fieldAnswerLocalId]: value,
        },
      };
    }),

  visibility: {},
  updateVisibility: (fieldAnswerLocalId, isVisible) =>
    set((state) => {
      return {
        visibility: {
          ...state.visibility,
          [fieldAnswerLocalId]: isVisible,
        },
      };
    }),
}));
