import React from 'react';
import {
  EsgReferenceOutSchema,
  FieldDependencyOutSchema,
  FieldOptionOutSchema,
  FieldOutSchema,
  QuestionnaireOutSchema,
  QuestionOutSchema,
  Qv3Options,
  Qv3Context as Qv3ContextType,
} from './types';
import { UUID } from 'crypto';
import {
  useEsgReferences,
  useFieldDependencies,
  useFieldOptions,
  useFields,
  useQuestionnaires,
  useQuestions,
} from './hooks';
import { UseResourceInterface } from 'src/common/types';

export const defaultOptions = {
  hints: {},
  withFieldReferences: false,
  withFieldComments: false,
  withEsgReferences: false,
};

export const Qv3Context = React.createContext<Qv3ContextType>({
  questionnaireAnswerId: undefined,
  questionnaireId: undefined,
  options: defaultOptions,

  isLoading: true,
  queries: {
    questionnaires: {} as UseResourceInterface<
      undefined,
      QuestionnaireOutSchema
    >,
    questions: {} as UseResourceInterface<undefined, QuestionOutSchema>,
    fields: {} as UseResourceInterface<undefined, FieldOutSchema>,
    fieldOptions: {} as UseResourceInterface<undefined, FieldOptionOutSchema>,
    esgReferences: {} as UseResourceInterface<undefined, EsgReferenceOutSchema>,
    fieldDependencies: {} as UseResourceInterface<
      undefined,
      FieldDependencyOutSchema
    >,
  },
});

interface Props {
  children: React.ReactNode;
  questionnaireId: UUID;
  questionnaireAnswerId: UUID;
  options?: Qv3Options;
}

// This Context is the questionnaire settings
// It provides the questionnaireId, questionnaireAnswerId and options
// It also provides the queries for the questionnaire, questions, fields, fieldOptions, fieldDependencies and esgReferences (GET)
// It also provides the isLoading status of the queries
// It also provides the modifiedQuestionAnswers object to keep track of the modified question answers
export default function Qv3ContextProvider(props: Readonly<Props>) {
  const { questionnaireId, questionnaireAnswerId, options = {} } = props;
  const opt = { ...defaultOptions, ...options };

  const queries = {
    questionnaires: useQuestionnaires({ id: questionnaireId }),
    questions: useQuestions({ questionnaire_id: questionnaireId }),
    fields: useFields({ question__questionnaire_id: questionnaireId }),
    fieldOptions: useFieldOptions({
      field__question__questionnaire_id: questionnaireId,
    }),
    fieldDependencies: useFieldDependencies({
      to_field__question__questionnaire_id: questionnaireId,
    }),
    esgReferences: useEsgReferences(
      { fields__question__questionnaire_id: questionnaireId },
      { enabled: options.withEsgReferences }
    ),
  };

  const isLoading = Object.values(queries).some((query) => query.isLoading);

  const _context = {
    questionnaireAnswerId,
    questionnaireId,
    options: opt,
    queries,
    isLoading,
  };

  const context = React.useMemo(() => _context, [_context]);

  return (
    <Qv3Context.Provider value={context}>{props.children}</Qv3Context.Provider>
  );
}
