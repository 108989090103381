import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDmaMaterialDatapoints } from 'src/Esg/Dmav2/hooks';
import { useEsgReports } from 'src/Esg/hooks';
import { EsgReferenceOutSchema } from 'src/QuestionnairesV3/types';

interface Props {
  reference: EsgReferenceOutSchema;
}
export default function Reference(props: Readonly<Props>) {
  const { reference } = props;
  const { t } = useTranslation('esg', { keyPrefix: 'common' });
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { data: _materiality } = useDmaMaterialDatapoints(report?.esg_dma_id);

  const materiality = _materiality?.find(
    (m) => m.esg_datapoint_id === reference.datapoint_id
  );

  const header = [
    reference.name,
    reference.number,
    reference.section,
    reference.subsection,
    reference.paragraph,
    reference.subparagraph,
    reference.clause,
  ]
    .filter((x) => !!x)
    .join(', ');

  const dataPointTypes = [
    reference.conditional_type ? t('conditional') : undefined,
    reference.mandatory_type === 'Voluntary' ? t('voluntary') : undefined,
  ].filter((x) => !!x);

  const isDPMandatory = dataPointTypes.length === 0;
  const dataPointType = (
    isDPMandatory ? [t('mandatory')] : dataPointTypes
  ).join(', ');

  return (
    <>
      <Typography variant="captionText" fontWeight="bold" component="span">
        {materiality?.is_material && (
          <Box color="darkred" fontStyle="normal">
            {t('material')}&nbsp;
          </Box>
        )}
        {reference.datapoint_id && (
          <span>
            {dataPointType} Datapoint ({reference.datapoint_id})&nbsp;
          </span>
        )}
        {header}
        {header && reference.related_ar && ', '}
        {reference.related_ar && (
          <Box component="span" sx={{ color: 'primary.main' }}>
            {reference.related_ar}
          </Box>
        )}
        {(header || reference.related_ar) && reference.text && ': '}
      </Typography>

      {reference.text && (
        <Typography
          variant="captionText"
          component="span"
        >{`"${reference.text}" `}</Typography>
      )}

      {reference.uri && (
        <Link variant="captionText" href={reference.uri} target="_blank">
          (link)
        </Link>
      )}
    </>
  );
}
