import { QueryOptions, useResourceController } from 'src/common/hooks';
import {
  FieldAnswerCommentsInSchema,
  FieldAnswerCommentsOutSchema,
  FieldAnswerInSchema,
  FieldAnswerOutSchema,
  FieldFilterSchema,
  FieldOptionFilterSchema,
  FieldOptionOutSchema,
  FieldOutSchema,
  QuestionAnswerInSchema,
  QuestionAnswerOutSchema,
  QuestionFilterSchema,
  QuestionnaireFilterSchema,
  QuestionnaireOutSchema,
  QuestionOutSchema,
  QuestionTableFilterSchema,
  QuestionTableOutSchema,
  Qv3EnumSchema,
  QuestionTableRowSchema,
  FieldAnswerFilterSchema,
  FieldDependencyOutSchema,
  FieldDependencyFilterSchema,
  EsgReferenceOutSchema,
  EsgReferenceFilterSchema,
} from './types';
import { useContext } from 'react';
import { Qv3Context } from './context';
import { UUID } from 'crypto';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  client,
  NinjaValidationData,
  NinjaValidationError,
} from 'src/utils/api-client';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

export const useQv3Context = () => useContext(Qv3Context);

export const useQuestionnaires = (
  queryParams: Partial<QuestionnaireFilterSchema> = {},
  queryOptions: QueryOptions<QuestionnaireOutSchema> | undefined = undefined
) => {
  return useResourceController<undefined, QuestionnaireOutSchema>(
    `/api-questionnaires/questionnaires`,
    ['qv3', 'questionnaires', queryParams],
    queryParams,
    queryOptions,
    ['GET']
  );
};

export const useQuestions = (
  queryParams: Partial<QuestionFilterSchema> = {},
  queryOptions: QueryOptions<QuestionOutSchema> | undefined = undefined
) =>
  useResourceController<undefined, QuestionOutSchema>(
    `/api-questionnaires/questions`,
    ['qv3', 'questions', queryParams],
    queryParams,
    queryOptions,
    ['GET']
  );

export const useFields = (
  queryParams: Partial<FieldFilterSchema> = {},
  queryOptions: QueryOptions<FieldOutSchema> | undefined = undefined
) =>
  useResourceController<undefined, FieldOutSchema>(
    `/api-questionnaires/fields`,
    ['qv3', 'fields', queryParams],
    queryParams,
    queryOptions,
    ['GET']
  );

export const useFieldOptions = (
  queryParams: Partial<FieldOptionFilterSchema> = {},
  queryOptions: QueryOptions<FieldOptionOutSchema> | undefined = undefined
) =>
  useResourceController<undefined, FieldOptionOutSchema>(
    `/api-questionnaires/field-options`,
    ['qv3', 'field-options', queryParams],
    queryParams,
    queryOptions,
    ['GET']
  );

export const useQuestionAnswers = (
  queryParams: Partial<QuestionAnswerOutSchema> = {},
  queryOptions: QueryOptions<QuestionAnswerOutSchema> | undefined = undefined
) =>
  useResourceController<QuestionAnswerInSchema, QuestionAnswerOutSchema>(
    `/api-questionnaires/question-answers`,
    ['qv3', 'question-answers', queryParams],
    queryParams,
    { invalidateKey: ['qv3', 'question-answers', queryParams], ...queryOptions }
  );

export const useFieldAnswers = (
  queryParams: Partial<FieldAnswerFilterSchema> = {},
  queryOptions: QueryOptions<FieldAnswerOutSchema> | undefined = undefined
) =>
  useResourceController<FieldAnswerInSchema, FieldAnswerOutSchema>(
    `/api-questionnaires/field-answers`,
    ['qv3', 'field-answers', queryParams],
    queryParams,
    { invalidateKey: ['qv3', 'field-answers', queryParams], ...queryOptions }
  );

export const useQuestionTables = (
  queryParams: Partial<QuestionTableFilterSchema> = {},
  queryOptions: QueryOptions<QuestionTableOutSchema> | undefined = undefined
) =>
  useResourceController<undefined, QuestionTableOutSchema>(
    `/api-questionnaires/question-tables`,
    ['qv3', 'question-tables', queryParams],
    queryParams,
    { invalidateKey: ['qv3', 'question-tables', queryParams], ...queryOptions },
    ['GET']
  );

export const useQuestionTable = (questionId: UUID) => {
  // this is not an api hook
  // but a function that returning helpfull data for the QuestionTable component
  const { queries } = useQv3Context();
  const question = queries.questions._dataMap[questionId];
  const questionTablesQuery = useQuestionTables({ id: question?.table_id });
  const { dimensions = [] } = questionTablesQuery._instance || {};
  const fields = queries.fields._data
    .filter((field) => field.question_id === questionId)
    .sort(
      (a, b) =>
        Number(dimensions.includes(b.id)) - Number(dimensions.includes(a.id))
    );

  const queryClient = useQueryClient();

  const updateRow = useMutation({
    mutationFn: (data: QuestionTableRowSchema) =>
      client
        .put(`/api-questionnaires/question-table-rows/${data.id}`, data)
        .then((res) => res.data)
        .catch((error: AxiosError) => {
          const data = error.response?.data as { detail?: NinjaValidationData };
          throw new NinjaValidationError(error.message, data?.detail || {});
        }),
    onSuccess: () => {
      queryClient.invalidateQueries(['qv3', 'question-answers']);
      queryClient.invalidateQueries(['qv3', 'field-answers']);
      queryClient.invalidateQueries(['qv3', 'question-tables']);
      toast.success('Success');
    },
    onError: (error: NinjaValidationError) => {
      if (error.errors._toast) toast.error(error.errors._toast);
    },
  });

  return {
    question,
    fields,
    dimensions,
    updateRow,
    queries: {
      questionTables: questionTablesQuery,
    },
  };
};

export const useQv3Enums = (id?: number) => {
  const queryKey = ['qv3Enums', { id }];
  const url = `/api-questionnaires/enums/${id}`;
  const query = useQuery<Qv3EnumSchema>(
    queryKey,
    () => client.get(url).then((res) => res.data),
    { enabled: !!id }
  );
  const qv3Enum = query.data || { name: null, default: null, options: [] };
  return { qv3Enum, ...query };
};

export const useFieldAnswerComments = (
  queryParams: Partial<FieldAnswerCommentsOutSchema> = {},
  queryOptions:
    | QueryOptions<FieldAnswerCommentsOutSchema>
    | undefined = undefined
) =>
  useResourceController<
    FieldAnswerCommentsInSchema,
    FieldAnswerCommentsOutSchema
  >(
    `/api-questionnaires/field-answer-comments`,
    ['qv3', 'field-answer-comments', queryParams],
    queryParams,
    queryOptions
  );

export const useFieldDependencies = (
  queryParams: Partial<FieldDependencyFilterSchema> = {},
  queryOptions: QueryOptions<FieldDependencyOutSchema> | undefined = undefined
) =>
  useResourceController<undefined, FieldDependencyOutSchema>(
    `/api-questionnaires/field-dependencies`,
    ['qv3', 'field-dependencies', queryParams],
    queryParams,
    queryOptions
  );

export const useEsgReferences = (
  queryParams: Partial<EsgReferenceFilterSchema> = {},
  queryOptions: QueryOptions<EsgReferenceOutSchema> | undefined = undefined
) =>
  useResourceController<undefined, EsgReferenceOutSchema>(
    `/web/esg/v3/esg-questionnaires/references`,
    ['qv3', 'esg-references', queryParams],
    queryParams,
    queryOptions,
    ['GET']
  );
