import React from 'react';
import { Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

import { useQuestionAnswers, useQv3Context } from '../hooks';
import Fields from '../Fields';
import { useLanguage } from 'src/common/hooks';
import { UUID } from 'src/utils/types';
import { QuestionPanel } from '../components/QuestionPanel';

interface Props {
  questionId: UUID;
}
export default function Question(props: Readonly<Props>) {
  const { questionId } = props;
  const { t } = useLanguage({ keyPrefix: 'questionnaireV3.questionAnswer' });
  const { queries } = useQv3Context();
  const question = queries.questions._dataMap[questionId];
  const { _data: questionAnswers, create } = useQuestionAnswers({
    question_id: questionId,
  });

  const isCreateAllowed = questionAnswers.length < 1 || !!question.allow_many;

  return (
    <QuestionPanel>
      <Typography variant="h5" fontWeight={700}>
        {question.title}
      </Typography>
      <Typography sx={{ mt: '10px' }} variant="body1">
        {question.subtitle}
      </Typography>
      {/* render fields for each questionAnswer */}
      {questionAnswers.map((questionAnswer) => (
        <Fields
          key={questionAnswer.id}
          questionId={question.id}
          questionAnswerId={questionAnswer.id}
        />
      ))}
      {isCreateAllowed && (
        <Button
          fullWidth
          startIcon={<Add />}
          variant="outlined"
          onClick={() => create.mutate({ question_id: question.id })}
          sx={{ mt: '12px', p: '12px', bgcolor: '#fafafa' }}
        >
          {t('createButton')}
        </Button>
      )}
    </QuestionPanel>
  );
}
