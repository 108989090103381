import React, { MouseEventHandler } from 'react';
import { TableCell } from '@mui/material';
import TouchRipple, {
  TouchRippleActions,
} from '@mui/material/ButtonBase/TouchRipple';

import { FieldOutSchema, FieldValueSchema } from 'src/QuestionnairesV3/types';
import { fieldValueToString } from 'src/QuestionnairesV3/utils';
import { useFieldOptions } from 'src/QuestionnairesV3/hooks';
import { useFieldVisibilitySubscription } from 'src/QuestionnairesV3/Fields/FieldAnswerValues/hooks';
import { useFieldAnswerLocal } from 'src/QuestionnairesV3/Fields/hooks';
import { DoNotDisturbAlt } from '@mui/icons-material';

interface Props {
  onClick: () => void;
  questionAnswerId: number;
  field: FieldOutSchema;
  value?: FieldValueSchema;
}

export default function ValueCell(props: Readonly<Props>) {
  const { onClick, value, field, questionAnswerId } = props;
  const rippleRef = React.useRef<TouchRippleActions | null>(null);
  const { _dataMap: optionsMap } = useFieldOptions({ field_id: field.id });
  const { isVisible } = useFieldAnswerLocal(questionAnswerId, field.id);

  const onRippleStart: MouseEventHandler<HTMLTableCellElement> = (e) => {
    rippleRef?.current?.start(e);
  };
  const onRippleStop: MouseEventHandler<HTMLTableCellElement> = (e) =>
    rippleRef?.current?.stop(e);

  useFieldVisibilitySubscription(questionAnswerId, field.id);

  const tableCellProps = isVisible && {
    onClick,
    onMouseDown: onRippleStart,
    onMouseUp: onRippleStop,
    sx: {
      textAlign: 'center',
      cursor: 'pointer',
      position: 'relative',
      ':hover': { background: '#eee' },
    },
  };

  return (
    <TableCell
      sx={{ textAlign: 'center', position: 'relative' }}
      {...tableCellProps}
    >
      {!isVisible && <DoNotDisturbAlt />}
      {isVisible && fieldValueToString(field.type, value, optionsMap)}
      {isVisible && <TouchRipple ref={rippleRef} center={false} />}
    </TableCell>
  );
}
