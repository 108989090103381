import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { StyledPanel } from 'src/components/StyledPanel';

export const QuestionPanel = (props: Readonly<BoxProps>) => (
  <StyledPanel
    m="24px 0px 48px"
    maxWidth="900px"
    mx="auto"
    minHeight="168px"
    sx={{ bgcolor: 'background.default', p: '32px' }}
    boxShadow="0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.06), 0px 2px 1px rgba(0,0,0,0.06)"
    {...props}
  />
);

export const StyledQuestionPanelBase = styled(Box)`
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 24px -32px;
`;

export const StyledQuestionPanelContent = styled(StyledQuestionPanelBase)`
  padding: 0px 32px;
`;

export const StyledQuestionPanelTableContent = StyledQuestionPanelBase;
