/* eslint-disable no-unused-vars */
import React from 'react';
import { FileSchema } from 'src/QuestionnairesV3/types';
import { Box, Chip } from '@mui/material';
import { UUID } from 'src/utils/types';

interface Props {
  onDeleteClick: (
    fileId: UUID,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void;
  value: FileSchema | FileSchema[];
}

export default function Files(props: Readonly<Props>) {
  const { value, onDeleteClick } = props;
  const values: FileSchema[] = Array.isArray(value) ? value : [value];
  if (!value) return null;
  return (
    <Box sx={{ display: 'flex', gap: '24px', flexWrap: 'wrap', mb: 2 }}>
      {values.map((file) => (
        <Chip
          key={file.id}
          label={file.name}
          onDelete={(event) => onDeleteClick(file.id, event)}
          onClick={() => window.open(file.url, '_blank')}
        />
      ))}
    </Box>
  );
}
