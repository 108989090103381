import React, { ChangeEvent } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { InputProps } from 'src/QuestionnairesV3/types';
import { useLanguage } from 'src/common/hooks';
import { useQv3Context } from 'src/QuestionnairesV3/hooks';

export default function SimpleSelect(props: Readonly<InputProps>) {
  const { error, value, onChange, fieldId } = props;
  const { t } = useLanguage();
  const { queries } = useQv3Context();
  const field = queries.fields._dataMap[fieldId];
  const fieldOptions = queries.fieldOptions._data.filter(
    (fo) => fo.field_id === fieldId
  );

  const booleanOptions = [
    { value: 'true', label: t('questionnaireV3.field.yes') },
    { value: 'false', label: t('questionnaireV3.field.no') },
  ];

  const options =
    field.type === 'boolean'
      ? booleanOptions
      : fieldOptions.map((option) => ({
          value: option.id,
          label: option.label,
        }));

  const handleChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    if (value === '') return onChange?.(null, e);
    if (value === 'true') return onChange?.(true, e);
    if (value === 'false') return onChange?.(false, e);
    return onChange?.(value, e as ChangeEvent);
  };

  return (
    <FormControl
      error={!!error}
      variant="outlined"
      fullWidth
      // disabled={disabled}
    >
      {field.label && <InputLabel id={field.id}>{field.label}</InputLabel>}
      <Select
        label={field.label}
        value={String(value ?? '')}
        onChange={handleChange}
        // disabled={!!disabled}
        fullWidth
        error={!!error}
      >
        {!field.required && (
          <MenuItem value="">
            <em>{t('questionnaireV3.field.none')}</em>
          </MenuItem>
        )}
        {options?.map((option) => (
          <MenuItem key={String(option.value)} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <Typography variant="errorHelperText">{error}</Typography>}
    </FormControl>
  );
}
