import { Subject } from 'rxjs';

import { FieldValueSchema } from 'src/QuestionnairesV3/types';
import { UUID } from 'src/utils/types';

// Event bus for broadcasting field value changes within a question-answer.
// This Subject allows different parts of the application to react to changes
// in field values — for example, to update visibility, perform validation,
// or trigger logic in dependent fields.

// eslint-disable-next-line import/prefer-default-export
export const fieldChange$ = new Subject<{
  questionAnswerId: number;
  fieldId: UUID;
  value: FieldValueSchema;
}>();
