import React from 'react';
import { filter } from 'rxjs';

import { useFieldAnswers, useQv3Context } from '../../hooks';
import { fieldChange$ } from '../FieldAnswerValues/eventBus';
import { useFieldAnswerValues } from '../FieldAnswerValues';
import { useFieldAnswerLocal } from '../hooks';
import { generateUUID } from 'src/utils/helpers';
import { UUID } from 'src/utils/types';
import {
  buildDependsOn,
  checkVisibilityLogic,
} from '../FieldAnswerValues/utils';
import {
  FieldAnswerLocalId,
  FieldAnswerOutSchema,
  FieldValueSchema,
} from 'src/QuestionnairesV3/types';

// this hook is used to build questionAnswerId-fieldId to fieldAnswerLocalId map
// and populate initial values (and values-signals) for all fieldAnswerLocalId in the current question
// isReady ensures that it's done only once when all fields are loaded and before the fields are rendered
export const usePopulateFieldAnswerValues = (
  questionAnswerId: number,
  questionId: UUID
) => {
  const [isReady, setIsReady] = React.useState(false);

  const { queries } = useQv3Context();
  const fieldAnswers = useFieldAnswers({
    question_answer_id: questionAnswerId,
  });

  React.useEffect(() => {
    // Skip if loading
    if (!fieldAnswers.isLoading && !queries.fields.isLoading && !isReady) {
      // Fetch all fields for the current question
      const fields = queries.fields._data.filter(
        (f) => f.question_id === questionId
      );

      // build a map of field answers for the current question answer by field ID
      const questionAnswerFieldAnswersMap = fieldAnswers._data
        .filter((fa) => fa.question_answer_id === questionAnswerId)
        .reduce<Record<UUID, FieldAnswerOutSchema>>((acc, fa) => {
          acc[fa.field_id] = fa;
          return acc;
        }, {});

      // build a map of field answers for the current question answer by field ID
      const initialValues: { [key: FieldAnswerLocalId]: FieldValueSchema } = {};
      const fieldsIdsToFieldAnswersLocalIds: {
        [key: UUID]: FieldAnswerLocalId;
      } = {};

      // populate initial values and field answers map
      fields.forEach((field) => {
        const fieldAnswer = questionAnswerFieldAnswersMap[field.id];
        const fieldAnswerLocalId = fieldAnswer?.id ?? generateUUID(true);
        fieldsIdsToFieldAnswersLocalIds[field.id] = fieldAnswerLocalId;
        initialValues[fieldAnswerLocalId] = fieldAnswer?.value ?? null;
      });

      // set initial values and field answers map in global state
      const _state = useFieldAnswerValues.getState();
      _state.setInitialValues(initialValues);
      _state.setFieldAnswersMap(
        questionAnswerId,
        fieldsIdsToFieldAnswersLocalIds
      );

      setIsReady(true);
    }
  }, [queries.fields, fieldAnswers, isReady]);

  return { isReady };
};

// This hook determines whether the field should be displayed based on dynamic
// dependencies on other fields' values. To be accessed with {isVisible} = useFieldAnswerLocal(...)
// Here's what it does:
// - Looks up all fields that the current field depends on (directly or indirectly).
// - Subscribes to value changes of those dependencies using `fieldChange$` (RxJS).
// - When a dependent field changes, it evaluates visibility logic via `checkVisibilityLogic(...)`.
// - Updates internal visibility state using Zustand (`useValuesSignals`).
export const useFieldVisibilitySubscription = (
  questionAnswerId: number,
  fieldId: UUID
) => {
  const { isLoading, queries } = useQv3Context();
  const { fieldAnswerLocalId } = useFieldAnswerLocal(questionAnswerId, fieldId);

  React.useEffect(() => {
    // Skip if loading
    if (isLoading) return () => {};

    // Update visibility based on dependencies
    const update = () => {
      const _state = useFieldAnswerValues.getState();
      const shouldShow = checkVisibilityLogic(
        questionAnswerId,
        fieldId,
        queries,
        _state.values,
        _state.fieldAnswersMap
      );
      _state.updateVisibility(fieldAnswerLocalId, shouldShow);

      // clear value if modified when field is hidden
      // to avoid submitting hidden fields or confuse isModified Question logic
      const initialValue = _state.initialValues[fieldAnswerLocalId];
      _state.updateValue(fieldAnswerLocalId, initialValue);
    };

    // Trigger initial visibility check
    update();

    // Build a list of all fields that this field depends on
    // This is a recursive function that returns all dependencies of a field
    const dependsOn = buildDependsOn(fieldId, queries.fieldDependencies._data);

    // Subscribe to field changes
    const subscription = fieldChange$
      .pipe(
        filter(
          ({ fieldId: changedFieldId, questionAnswerId: changedQAId }) =>
            changedQAId === questionAnswerId &&
            dependsOn.includes(changedFieldId)
        )
      )
      .subscribe(update);

    // Unsubscribe on unmount
    return () => subscription.unsubscribe();
  }, [questionAnswerId, fieldId, queries, isLoading, fieldAnswerLocalId]);
};
