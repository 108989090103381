import React from 'react';
import * as Sentry from '@sentry/react';
import { InputProps, VisualType } from '../../types';
import SimpleTextField from './SimpleTextField';
import SimpleSelect from './SimpleSelect';
import ToggleButtonGroup from './ToggleButtonGroup';
import DecimalTextField from './DecimalTextField';
import DecimalWithUnit from './DecimalWithUnit';
import SimpleCheckbox from './SimpleCheckbox';
import RadioSelect from './RadioSelect';
import { Typography } from '@mui/material';
import FileUpload from './FileUpload';
import { useQv3Context } from 'src/QuestionnairesV3/hooks';

export default function FieldInput(props: Readonly<InputProps>) {
  const { fieldId } = props;
  const { queries } = useQv3Context();
  const field = queries.fields._dataMap[fieldId];
  const visualType = field.visual_type;

  if (visualType === VisualType.SimpleTextField)
    return <SimpleTextField {...props} />;
  if (visualType === VisualType.DecimalTextField)
    return <DecimalTextField {...props} />;
  if (visualType === VisualType.DecimalWithUnitSelectHorizontal)
    return <DecimalWithUnit {...props} />;
  else if (visualType === VisualType.SimpleCheckbox)
    return <SimpleCheckbox {...props} />;
  else if (visualType === VisualType.SimpleSelect)
    return <SimpleSelect {...props} />;
  else if (visualType === VisualType.RadioGroup)
    return <RadioSelect {...props} />;
  else if (visualType === VisualType.ToggleButtonGroup)
    return <ToggleButtonGroup {...props} />;
  else if (visualType === VisualType.SimpleFilePicker)
    return <FileUpload {...props} />;
  else if (visualType === VisualType.MultiFilePicker)
    return <FileUpload {...props} />;

  // capture Sentry error
  const msg = `${field.type} [${field.visual_type}] is unknown field type.`;
  Sentry.captureException(msg);

  return (
    <Typography>
      Component with visual type {field.visual_type} is not available.
    </Typography>
  );
}
