import React from 'react';
import { Box } from '@mui/material';

import Section from '../components/Section';
import { useQv3Context } from '../hooks';

import Question from './Question';
import QuestionTable from './QuestionTable';

export default function Questions() {
  const { queries, isLoading } = useQv3Context();
  const { _data: questions } = queries.questions;
  if (isLoading) return null;
  return (
    <Box>
      <Section section="questions" />
      {questions.map((question) => {
        if (question.table_id)
          return <QuestionTable key={question.id} questionId={question.id} />;
        return <Question key={question.id} questionId={question.id} />;
      })}
    </Box>
  );
}
