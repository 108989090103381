import { v4 } from 'uuid';
import { appConfig } from '../config';
import { UUID } from './types';

export const uppercaseRegExp = /(?=.*?[A-Z])/;
export const lowercaseRegExp = /(?=.*?[a-z])/;
export const digitsRegExp = /(?=.*?[0-9])/;
export const minLengthRegExp = /.{8,}/;
export const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
export const closeEnoughEmailRegExp = /.+@.+\..+/;

export const isValidPassword = (value: string): boolean =>
  uppercaseRegExp.test(value) &&
  lowercaseRegExp.test(value) &&
  digitsRegExp.test(value) &&
  minLengthRegExp.test(value) &&
  specialCharRegExp.test(value);

export const autoCompleteOptions = (value: string | [string]): string => {
  if (typeof value === 'string') {
    return value;
  } else {
    return value[0];
  }
};

export const activeHelper = (value: boolean | number | undefined): boolean => {
  if (typeof value === 'number') {
    return true;
  } else if (typeof value === 'undefined') {
    return false;
  } else if (typeof value === 'boolean') {
    return value;
  } else {
    return value;
  }
};

export const toLocaleNumber = (value: any, lang: string) =>
  (+value).toLocaleString(lang);

export const parseRequestErrors = (data: any) => {
  const errors: { [key: string]: string } = {};
  for (const err of data?.detail || []) {
    const message = err.msg;
    const loc = err?.loc || [];
    const field = loc.pop();
    errors[field] = message;
  }
  return errors;
};
export const resolveMediaUrl = (url: string) => {
  if (!url) return url;
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  // resolve relative URLs by combining host with path (removing leading/trailing slashes)
  return `${appConfig.backendRootUrl}/${url
    .split('/')
    .filter(Boolean)
    .join('/')}`;
};

// Returns a copy of an object, with all fields of `''` replaced by `null`
export const withEmptyStringsAsNull = <T extends object>(obj: T): T => {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [k, v === '' ? null : v])
  ) as T;
};

export const formatNumberWithPrecision = (
  input: number | string,
  decimalPlaces = 5,
  minTrailingZeros = 0
): string => {
  const num = typeof input === 'string' ? parseFloat(input) : input;

  if (Number.isNaN(num)) {
    return typeof input === 'string' ? input : '';
  }

  const options = {
    minimumFractionDigits: minTrailingZeros,
    maximumFractionDigits: decimalPlaces,
    useGrouping: false, // This option disables comma or period as thousand separators
  };

  return num.toLocaleString('en-US', options);
};

export const saveBlob = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  a.remove();
};

// Generates a UUID. If `isLocal` is true, it returns a locally generated (temporary) UUID
// with a `$` prefix to distinguish it from backend-generated ones.
export const generateUUID = (isLocal: boolean = false): UUID => {
  const rawUUID = v4();
  return (isLocal ? `$${rawUUID.slice(1)}` : rawUUID) as UUID;
};
