import { Info } from '@mui/icons-material';
import { IconButton, List, ListItem, ListItemText } from '@mui/material';

import React from 'react';
import LightTooltip from 'src/components/LightTooltip';
import Reference from 'src/QuestionnairesV3/Fields/References/EsgReferences/Reference';
import { useQv3Context } from 'src/QuestionnairesV3/hooks';
import { UUID } from 'src/utils/types';

interface Props {
  fieldId: UUID;
}
export default function EsgReferenceIcon(props: Readonly<Props>) {
  const { fieldId } = props;
  const { queries } = useQv3Context();
  const references = queries.esgReferences._data.filter((r) =>
    r.fields?.includes(fieldId)
  );
  if (!references.length) return null;

  return (
    <LightTooltip
      title={
        <List dense>
          {references.map((reference) => (
            <ListItem key={reference.id}>
              <ListItemText>
                <Reference reference={reference} />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      }
    >
      <IconButton size="small" sx={{ cursor: 'default' }}>
        <Info fontSize="small" />
      </IconButton>
    </LightTooltip>
  );
}
