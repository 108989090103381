import React from 'react';
import { TableCell, Typography } from '@mui/material';
import { useFieldVisibilitySubscription } from 'src/QuestionnairesV3/Fields/FieldAnswerValues/hooks';
import { updateLocalValue } from 'src/QuestionnairesV3/Fields/FieldAnswerValues/utils';
import { useFieldAnswerLocal } from 'src/QuestionnairesV3/Fields/hooks';
import FieldInput from 'src/QuestionnairesV3/Fields/Inputs';
import { FieldValueSchema } from 'src/QuestionnairesV3/types';
import { UUID } from 'src/utils/types';
import { DoNotDisturbAlt } from '@mui/icons-material';

interface Props {
  questionAnswerId: number;
  fieldId: UUID;
  value: FieldValueSchema;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: FieldValueSchema) => void;
  error?: string;
  sx?: React.CSSProperties;
}

export default function ValueCell(props: Readonly<Props>) {
  const { questionAnswerId, fieldId, sx, value, error, onChange } = props;
  const { isVisible } = useFieldAnswerLocal(questionAnswerId, fieldId);
  useFieldVisibilitySubscription(questionAnswerId, fieldId);

  const verticalAlign = isVisible ? 'top' : 'center';

  return (
    <TableCell key={fieldId} size="small" sx={{ verticalAlign, ...sx }}>
      {!isVisible && (
        <Typography textAlign="center">
          <DoNotDisturbAlt />
        </Typography>
      )}
      {isVisible && (
        <FieldInput
          fieldId={fieldId}
          value={value ?? ''}
          error={error}
          onChange={(value) => {
            updateLocalValue(questionAnswerId, fieldId, value);
            onChange(value);
          }}
        />
      )}
    </TableCell>
  );
}
