import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledFlex } from 'src/components/StyledFlex';
import { useQv3Context } from 'src/QuestionnairesV3/hooks';
import Reference from './Reference';
import { UUID } from 'crypto';

interface Props {
  fieldId: UUID;
}

export default function EsgReferences(props: Readonly<Props>) {
  const { fieldId } = props;
  const { t } = useTranslation('esg', { keyPrefix: 'common' });
  const { queries } = useQv3Context();
  const references = queries.esgReferences._data.filter((r) =>
    r.fields?.includes(fieldId)
  );

  const [isCollapseOpen, setIsCollapseOpen] = React.useState(true);

  if (!references.length) return null;

  return (
    <Box
      fontStyle="italic"
      color="#505050"
      border="1px solid #D4E3FB"
      borderRadius="4px"
      bgcolor="#F2F6FC"
      p="8px 16px"
      mt={2}
    >
      <StyledFlex>
        <Typography variant="captionText">{t('references')}:</Typography>
        <IconButton onClick={() => setIsCollapseOpen((x) => !x)}>
          <KeyboardArrowDown />
        </IconButton>
      </StyledFlex>

      <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
        <List dense>
          {references.map((reference) => (
            <ListItem key={reference.id}>
              <ListItemText>
                <Reference reference={reference} />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Box>
  );
}
