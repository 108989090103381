import React from 'react';
import { useFieldAnswers } from 'src/QuestionnairesV3/hooks';
import ValueCell from './ValueCell';
import { FieldOutSchema } from 'src/QuestionnairesV3/types';
import { UUID } from 'src/utils/types';
import PopulateFieldAnswersProvider from 'src/QuestionnairesV3/Fields/FieldAnswerValues/Provider';

interface Props {
  questionId: UUID;
  questionAnswerId: number;
  field: FieldOutSchema;
  // eslint-disable-next-line no-unused-vars
  onClick: (fieldAnswer: any) => void;
}

// we need this middle component to call useFieldAnswers by question_answer_id
// for query invalidation to work in a optimal way
// this PreRender component is used to populate initial values for all fields in the current question
export default function Answer(props: Readonly<Props>) {
  const { questionId, questionAnswerId, field, onClick } = props;

  const fieldAnswers = useFieldAnswers({
    question_answer_id: questionAnswerId,
  });

  const fieldAnswer = fieldAnswers._data.find((fa) => fa.field_id === field.id);

  return (
    <PopulateFieldAnswersProvider
      questionAnswerId={questionAnswerId}
      questionId={questionId}
    >
      <ValueCell
        questionAnswerId={questionAnswerId}
        field={field}
        value={fieldAnswer?.value}
        onClick={() => onClick(field.id)}
      />
    </PopulateFieldAnswersProvider>
  );
}
