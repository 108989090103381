import { createTheme } from '@mui/material';

import theme from '../theme';
import customTypography from 'src/theme/typographies';

import { MAIN_COLOR, TEXT_COLOR } from './utils';
import { deepmerge } from '@mui/utils';
import dialogTheme from 'src/theme/dialog';

const qv3Theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    ...customTypography,
  },
  palette: {
    ...theme.palette,
    primary: {
      main: MAIN_COLOR,
    },
    error: {
      main: '#A03137', // Primary error color
      light: '#D0424C', // Lighter error shade (optional)
      dark: '#73091A', // Darker error shade (optional)
    },
    background: {
      default: '#F2F2F2',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          paddingLeft: '24px',
          paddingRight: '24px',
          fontWeight: 600,
          boxShadow: 'none',
          borderRadius: 28,
          fontSize: 18,
          lineHeight: 1.25,
          minWidth: '144px',
          textTransform: 'none',
          ':hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: TEXT_COLOR,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            color: '#38414F',
            borderRadius: '8px',
            fontSize: '16px',
            fontWeight: 600,
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.2)',
          width: '600px',
          borderRadius: '16px',
          marginTop: '6px',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            sx: { padding: 0 },
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '0.32px',
          borderRadius: '8px',
          '&.Mui-error': {
            borderColor: '#A03137',
            color: '#A03137',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: { borderRadius: '16px ', padding: 0 },
      },
    },
    MuiMenuItem: {
      styleOverrides: { root: { gap: '10px' } },
    },
    //   MuiDialogTitle: {
    //     defaultProps: {
    //       align: 'left',
    //     },
    //     styleOverrides: {
    //       root: {
    //         '& .MuiTypography-root': {
    //           // use overrided Typography directly
    //           ...dialogTitleTypography,
    //         },
    //       },
    //     },
    //   },
    //   MuiDialogContent: {
    //     styleOverrides: {
    //       root: {
    //         paddingLeft: muiDialogPadding,
    //         paddingRight: muiDialogPadding,
    //         paddingTop: '24px !important', // text field getting padding-top: 0; this cuts off the label
    //         paddingBottom: muiDialogPadding,
    //       },
    //     },
    //   },
    //   MuiDialogActions: {
    //     styleOverrides: {
    //       root: {
    //         padding: muiDialogPadding,
    //       },
    //     },
    //   },
    //   MuiFormControl: {
    //     styleOverrides: {
    //       root: {
    //         '& .Mui-error .MuiSvgIcon-root': {
    //           color: ERROR_COLOR,
    //         },
    //         '& .Mui-error .MuiTypography-root': {
    //           color: ERROR_COLOR,
    //         },
    //         '& .Mui-disabled .MuiTypography-root': {
    //           color: DISABLED_COLOR,
    //         },
    //         '& .MuiFormLabel-root': {
    //           fontSize: '16px',
    //           fontWeight: 600,
    //           lineHeight: '24px',
    //           letterSpacing: '0.32px',
    //           '&.Mui-focused': {
    //             color: TEXT_COLOR,
    //           },
    //           '&.Mui-error': {
    //             color: ERROR_COLOR,
    //           },
    //         },
    //         '& .Mui-error .MuiFormLabel-root': {
    //           color: ERROR_COLOR,
    //         },
    //         '& .MuiRating-icon': {
    //           color: themeColors.main,
    //         },
    //         '& .MuiRating-root': {
    //           '&.Mui-disabled': {
    //             '& .MuiRating-icon': {
    //               color: DISABLED_COLOR,
    //             },
    //           },
    //           '&.Mui-error': {
    //             '& .MuiRating-icon': {
    //               color: ERROR_COLOR,
    //             },
    //           },
    //         },
    //       },
    //     },
    //   },
    //   MuiOutlinedInput: {
    //     styleOverrides: {
    //       root: {
    //         fontSize: '16px',
    //         fontWeight: 600,
    //         lineHeight: '24px',
    //         letterSpacing: '0.32px',
    //         borderRadius: '8px',
    //         '&:hover .MuiOutlinedInput-notchedOutline': {
    //           borderColor: themeColors.dark,
    //         },
    //         '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    //           borderColor: ERROR_COLOR,
    //         },
    //         '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    //           borderColor: '#bababa',
    //         },
    //       },
    //     },
    //   },
    //   MuiSelect: {
    //     styleOverrides: {
    //       root: {
    //         '& .MuiOutlinedInput-input': {
    //           fontSize: '16px',
    //           fontWeight: 600,
    //           lineHeight: '24px',
    //           letterSpacing: '0.32px',
    //         },
    //         '& .MuiOutlinedInput-notchedOutline': {
    //           borderRadius: '8px',
    //         },
    //         '&:hover .MuiOutlinedInput-notchedOutline': {
    //           borderColor: themeColors.dark,
    //         },
    //         '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    //           borderColor: ERROR_COLOR,
    //         },
    //         '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    //           borderColor: '#bababa',
    //         },
    //       },
    //     },
    //   },
    //   MuiFormControlLabel: {
    //     styleOverrides: {
    //       root: {
    //         '&.Mui-disabled': {
    //           color: DISABLED_COLOR,
    //         },
    //       },
    //     },
    //   },
    //   MuiToggleButton: {
    //     styleOverrides: {
    //       root: {
    //         textTransform: 'none',
    //         '&:hover': {
    //           backgroundColor: HOVER_COLOR,
    //         },
    //         fontSize: '16px',
    //         fontWeight: 600,
    //         lineHeight: '24px',
    //         letterSpacing: '0.32px',
    //         borderRadius: '8px',
    //         '&.Mui-error': {
    //           borderColor: ERROR_COLOR,
    //           color: ERROR_COLOR,
    //         },
    //         '&.Mui-selected': {
    //           backgroundColor: themeColors.main,
    //           color: themeColors.contrastText,
    //           '&:hover': {
    //             backgroundColor: themeColors.light,
    //           },
    //           '&.Mui-disabled': {
    //             backgroundColor: HOVER_COLOR,
    //             color: DISABLED_COLOR,
    //           },
    //           '&.Mui-error': {
    //             backgroundColor: ERROR_COLOR,
    //             color: '#fff',
    //             '&:hover': {
    //               backgroundColor: ERROR_COLOR,
    //             },
    //           },
    //         },
    //       },
    //     },
    //   },
    //   MuiButton: {
    //     styleOverrides: {
    //       textSecondary: {
    //         color: '#38414F',
    //         border: '1px solid #D9D9D9',
    //         ':hover': {
    //           color: '#38414F',
    //         },
    //       },
    //       root: {
    //         fontWeight: 600,
    //         boxShadow: 'none',
    //         borderRadius: 28,
    //         fontSize: 18,
    //         padding: '0 24px',
    //         textTransform: 'none',
    //         '&.MuiButton-containedSecondary': {
    //           border: '1px solid #D9D9D9',
    //           backgroundColor: '#fff',
    //           height: '40px',
    //           color: '#38414F',
    //           fontSize: '14px',
    //           fontWeight: 700,
    //           alignSelf: 'center',
    //           padding: '0 18px',
    //           '&:hover': {
    //             backgroundColor: 'rgba(0, 0, 0, 0.04)',
    //           },
    //           '&:pressed': {
    //             backgroundColor: 'rgba(0, 0, 0, 0.08)',
    //           },
    //           '& .MuiButton-endIcon': {
    //             color: '#38414F',
    //             fontSize: '24px ',
    //             marginLeft: '3px',
    //           },
    //         },
    //         '&.file': {
    //           height: 40,
    //           backgroundColor: themeColors.main,
    //           borderRadius: 16,
    //           paddingLeft: 16,
    //           textTransform: 'unset',
    //           '& a': {
    //             color: '#38414f',
    //             fontSize: 14,
    //             fontStyle: 'normal',
    //             fontWeight: 600,
    //             lineHeight: '18px',
    //             letterSpacing: '0.28px',
    //             textDecoration: 'none',
    //             '&:hover': {
    //               textDecoration: 'underline',
    //             },
    //           },
    //         },
    //         '&.attache': {
    //           color: '#38414f',
    //           textAlign: 'center',
    //           fontSize: 16,
    //           fontStyle: 'normal',
    //           fontWeight: 600,
    //           lineHeight: '18px',
    //           letterSpacing: '0.32px',
    //           display: 'flex',
    //           height: 40,
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //           borderRadius: 24,
    //           border: '1px solid  #ccc',
    //           background: '#fff',
    //           marginTop: 32,
    //           padding: '0 26px',
    //           textTransform: 'unset',
    //           '&:hover': {
    //             border: '1px solid #ccc',
    //             background: 'rgba(0, 0, 0, 0.04)',
    //           },
    //           '&:active': {
    //             border: '1px solid  rgba(0, 0, 0, 0.08)',
    //             background: 'rgba(0, 0, 0, 0.08)',
    //           },
    //         },
    //       },
    //       // @ts-ignore
    //       secondary: {
    //         border: '1px solid #D9D9D9',
    //         backgroundColor: '#fff',
    //         height: '40px',
    //         color: '#38414F',
    //         fontSize: '14px',
    //         fontWeight: 700,
    //         alignSelf: 'center',
    //         padding: '0 18px',
    //         '&:hover': {
    //           backgroundColor: 'rgba(0, 0, 0, 0.04)',
    //         },
    //         '&:pressed': {
    //           backgroundColor: 'rgba(0, 0, 0, 0.08)',
    //         },
    //         '& .MuiButton-endIcon': {
    //           color: '#38414F',
    //           fontSize: '24px ',
    //           marginLeft: '3px',
    //         },
    //       },
    //     },
    //   },
    //   // @ts-ignore
    //   EsgDescriptions: {
    //     styleOverrides: {
    //       root: {
    //         '& > p': {
    //           backgroundColor: themeColors.main,
    //           color: themeColors.contrastText,
    //         },
    //       },
    //     },
    //   },
  },
});
export default deepmerge(dialogTheme, qv3Theme);
