import { UUID } from 'src/utils/types';
import { useFieldAnswerValues } from './FieldAnswerValues';
import { FieldValueSchema } from '../types';

export const useIsQuestionAnswerModified = (questionAnswerId: number) => {
  const _state = useFieldAnswerValues((state) => state);
  const fieldAnswerTempIds = _state.fieldAnswersMap[questionAnswerId];

  const _initialValues: Record<number | UUID, FieldValueSchema> = {};
  const _values: Record<number | UUID, FieldValueSchema> = {};

  for (const [_, value] of Object.entries(fieldAnswerTempIds)) {
    _initialValues[value] = _state.initialValues[value];
    _values[value] = _state.values[value];
  }

  return JSON.stringify(_initialValues) !== JSON.stringify(_values);
};

export const useFieldAnswerLocal = (
  questionAnswerId: number,
  fieldId: UUID
) => {
  const fieldAnswerLocalId = useFieldAnswerValues(
    (state) => state.fieldAnswersMap[questionAnswerId][fieldId]
  );
  const value = useFieldAnswerValues(
    (state) => state.values[fieldAnswerLocalId]
  );
  const initialValue = useFieldAnswerValues(
    (state) => state.initialValues[fieldAnswerLocalId]
  );
  const isVisible = useFieldAnswerValues(
    (state) => state.visibility[fieldAnswerLocalId]
  );
  const isModified = value !== initialValue;

  return { fieldAnswerLocalId, value, initialValue, isModified, isVisible };
};
