import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  Typography,
} from '@mui/material';
import { InputProps } from 'src/QuestionnairesV3/types';
import { useQv3Context } from 'src/QuestionnairesV3/hooks';

export default function ToggleButtonGroup(props: Readonly<InputProps>) {
  const { t } = useTranslation();
  const { error, value, fieldId, onChange } = props;
  const { queries } = useQv3Context();
  const field = queries.fields._dataMap[fieldId];
  const fieldOptions = queries.fieldOptions._data.filter(
    (fo) => fo.field_id === fieldId
  );

  const booleanOptions = [
    { value: 'true', label: t('questionnaireV3.field.yes') },
    { value: 'false', label: t('questionnaireV3.field.no') },
  ];

  const options =
    field.type === 'boolean'
      ? booleanOptions
      : fieldOptions.map((option) => ({
          value: option.id,
          label: option.label,
        }));

  return (
    <>
      {field.label && (
        <Typography sx={{ mt: '-16px', mb: '16px' }}>{field.label}</Typography>
      )}
      <MuiToggleButtonGroup
        fullWidth
        exclusive
        value={value}
        onChange={(event, newValue) => onChange?.(newValue, event)}
        // disabled={disabled}
      >
        {options.map((option) => (
          <ToggleButton
            key={option.value}
            value={option.value}
            // disabled={disabled}
            className={error && 'Mui-error'}
          >
            {option.label}
          </ToggleButton>
        ))}
      </MuiToggleButtonGroup>
      {error && <Typography variant="errorHelperText">{error}</Typography>}
    </>
  );
}
