import React from 'react';
import { UUID } from 'src/utils/types';
import { usePopulateFieldAnswerValues } from './hooks';

export interface Props {
  questionId: UUID;
  questionAnswerId: number;
  children: React.ReactNode;
}

// this pre-render component is used to build questionAnswerId-fieldId to fieldAnswerLocalId map
// and populate initial values (and values-signals) for all fieldAnswerLocalId in the current question
// only once when all fields are loaded
// and before the fields are rendered
export default function PopulateFieldAnswersProvider(props: Readonly<Props>) {
  const { questionAnswerId, questionId } = props;
  const { isReady } = usePopulateFieldAnswerValues(
    questionAnswerId,
    questionId
  );
  if (!isReady) return null;
  return props.children as React.ReactElement;
}
