import * as Sentry from '@sentry/react';

import {
  FieldAnswerLocalId,
  FieldDependencyOutSchema,
  FieldValueSchema,
  Qv3ContextQueries,
} from 'src/QuestionnairesV3/types';
import { UUID } from 'src/utils/types';
import { fieldValueToString } from 'src/QuestionnairesV3/utils';
import { useFieldAnswerValues } from '.';
import { fieldChange$ } from './eventBus';

// Update value locally and broadcast to global signal/event system
export const updateLocalValue = (
  questionAnswerId: number,
  fieldId: UUID,
  _value: FieldValueSchema
) => {
  const fieldAnswerLocalId =
    useFieldAnswerValues.getState().fieldAnswersMap[questionAnswerId][fieldId];
  useFieldAnswerValues.getState().updateValue(fieldAnswerLocalId, _value);
  fieldChange$.next({ questionAnswerId, fieldId, value: _value });
};

// Recursively builds a list of all field IDs that the given `fieldId` depends on.
// This includes both direct dependencies  and indirect (nested) dependencies
export const buildDependsOn = (
  fieldId: UUID,
  fieldDependencies: FieldDependencyOutSchema[]
): UUID[] => {
  const visited = new Set<UUID>(); // Keep track of visited fields to avoid infinite loops
  const result: UUID[] = []; // Resulting list of dependencies (flattened)

  // Depth-first search (DFS) traversal function
  const dfs = (currentId: UUID) => {
    // Find all fields that `currentId` depends on directly
    const directDeps = fieldDependencies
      .filter((d) => d.to_field_id === currentId)
      .map((d) => d.from_field_id);

    // Traverse each dependency recursively
    for (const depId of directDeps) {
      if (!visited.has(depId)) {
        visited.add(depId); // mark as visited
        result.push(depId); // store in result
        dfs(depId); // recurse into its own dependencies
      }
    }
  };
  // Start traversal from the given fieldId
  dfs(fieldId);

  // Return the complete list of dependencies
  return result;
};

// Checks the visibility logic for a given field based on its dependencies
// and the current values of all fields.
// Includes checking visibility for nested dependencies.
export const checkVisibilityLogic = (
  questionAnswerId: number,
  fieldId: UUID,
  queries: Qv3ContextQueries,
  valuesSignals: Record<FieldAnswerLocalId, FieldValueSchema>,
  fieldAnswersMap: Record<number, Record<UUID, FieldAnswerLocalId>>,
  visited = new Set<UUID>() // for cycle detection
) => {
  // check if field is already visited
  if (visited.has(fieldId)) {
    const msg = `Recursion detected on field dependency: ${fieldId}`;
    console.error(msg);
    Sentry.captureException(msg);
    return false;
  }
  // mark field as visited to avoid infinite loops
  visited.add(fieldId);

  // get the field
  const field = queries.fields._dataMap[fieldId];
  if (!field) return false;

  // gather all dependencies for the field
  const dependencies = queries.fieldDependencies._data.filter(
    (d) => d.to_field_id === field.id
  );

  // if no dependencies, field is visible
  if (dependencies.length === 0) return true;

  for (const d of dependencies) {
    // get the field that the dependency is based on
    const _field = queries.fields._dataMap[d.from_field_id];

    // optionally gather all field options for the field
    const fieldOptionsMap = queries.fieldOptions._dataMap;

    // parse the value to string
    const parseValue = (_v: FieldValueSchema) =>
      fieldValueToString(_field.type, _v, fieldOptionsMap);

    // parse values to string for comparison
    const fromFieldAnswerLocalId =
      fieldAnswersMap[questionAnswerId][d.from_field_id];
    const fromFieldValue = parseValue(valuesSignals[fromFieldAnswerLocalId]);
    const dependencyValue = parseValue(d.value);

    // checking dependency and nested dependencies
    const dependencyMet = dependencyValue === fromFieldValue;
    const nestedDependencyMet = checkVisibilityLogic(
      questionAnswerId,
      d.from_field_id,
      queries,
      valuesSignals,
      fieldAnswersMap,
      new Set(visited)
    );

    // resolve visibility based on action
    const resolveVisibility = d.action === 'show';

    // return true if dependency and nested dependencies are met
    if (dependencyMet && nestedDependencyMet) return resolveVisibility;
  }
  // if no dependency is met, field is not visible
  return false;
};
