import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '../ReportsIndex/index.styles';
import { useEsgOrganization } from 'src/common/hooks';
import QuestionnaireV3 from '../../../components/QuestionnaireV3';

import {
  createEsgTokenFetcher,
  useEsgQuestionnaireAnswerId,
  useEsgQv3PreviousAnswerHints,
  useEsgQv3References,
} from './hooks';
import { useEsgReports } from 'src/Esg/hooks';
import { parseNumericId } from 'src/utils/navigation';
import { useDmaMaterialDatapoints } from 'src/Esg/Dmav2/hooks';
import {
  IReferencesContext,
  ReferecesContext,
} from 'src/views/components/QuestionnaireV3/Question/references';

export default function ESGForm() {
  const params = useParams<{
    formKey: string;
    reportId: string;
  }>();

  const organization = useEsgOrganization();
  const reportId = String(params.reportId);
  const questionnaireKey = String(params.formKey);

  const { data: { questionnaireAnswerId, questionnaireId } = {} } =
    useEsgQuestionnaireAnswerId(reportId, organization.id, questionnaireKey);

  const { _instance: report } = useEsgReports({ id: parseNumericId(reportId) });

  const tokenFetcher = useMemo(
    () =>
      createEsgTokenFetcher(organization.id, reportId, questionnaireAnswerId),
    [organization.id, reportId, questionnaireAnswerId]
  );

  const { data: _references } = useEsgQv3References(
    questionnaireKey,
    questionnaireId
  );
  const { data: _materiality } = useDmaMaterialDatapoints(report?.esg_dma_id);

  const references: IReferencesContext = useMemo(() => {
    const references = _references || { questions: {}, fields: {} };
    const datapointMateriality = Object.fromEntries(
      _materiality?.map((x) => [x.esg_datapoint_id, x]) || []
    );
    return { ...references, datapointMateriality };
  }, [_references, _materiality]);

  const { hints } = useEsgQv3PreviousAnswerHints(questionnaireAnswerId);

  return (
    <Container>
      <ReferecesContext.Provider value={references}>
        <QuestionnaireV3
          key={questionnaireAnswerId}
          questionnaireAnswerId={questionnaireAnswerId}
          tokenAuth={tokenFetcher}
          withBulkSaveButtons
          withEsgReviewPanel
          hints={hints}
        />
      </ReferecesContext.Provider>
    </Container>
  );
}
