import React, { useState } from 'react';
import {
  useFieldAnswers,
  useQuestionAnswers,
  useQuestionTable,
} from 'src/QuestionnairesV3/hooks';
import { FieldValueSchema } from 'src/QuestionnairesV3/types';
import ConfirmationModal from 'src/components/ConfirmationModal';
import TableRowWithDimensions from '../../components/TableRowWithDimensions';
import ErrorRow from '../../Rows/Error';
import ActionButtons from '../../Rows/ActionButtons';
import PopulateFieldAnswersProvider from 'src/QuestionnairesV3/Fields/FieldAnswerValues/Provider';
import ValueCell from './ValueCell';
import { UUID } from 'src/utils/types';

interface Props {
  questionId: UUID;
  questionAnswerId: number;
}

// this PreRender component is used to populate initial values for all fields in the current question
export default function Answer(props: Readonly<Props>) {
  const { questionId, questionAnswerId } = props;
  const { _data } = useFieldAnswers({ question_answer_id: questionAnswerId });
  const { _delete } = useQuestionAnswers({ question_id: questionId });
  const { fields, dimensions, updateRow } = useQuestionTable(questionId);

  const [values, setValues] = useState<Record<UUID, FieldValueSchema>>({});
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  // Convert the data to a more usable format, mapping field_id to value
  const originalValues = React.useMemo(() => {
    return _data.reduce((acc: Record<UUID, FieldValueSchema>, item) => {
      acc[item.field_id] = item.value;
      return acc;
    }, {});
  }, [_data]);

  React.useEffect(() => {
    setValues(originalValues);
  }, [originalValues]);

  const modified = JSON.stringify(values) !== JSON.stringify(originalValues);
  const modifiedSx = modified ? { borderBottom: 'none' } : {};
  const errors = updateRow.error?.errors || {};

  return (
    <PopulateFieldAnswersProvider
      questionAnswerId={questionAnswerId}
      questionId={questionId}
    >
      <TableRowWithDimensions dimensionsCount={dimensions.length}>
        {fields.map((field) => {
          return (
            <ValueCell
              key={field.id}
              questionAnswerId={questionAnswerId}
              fieldId={field.id}
              sx={modifiedSx}
              value={values[field.id]}
              error={errors[field.id]}
              onChange={(value) => {
                setValues((prevState) => ({
                  ...prevState,
                  [field.id]: value,
                }));
              }}
            />
          );
        })}
      </TableRowWithDimensions>
      {errors.row && <ErrorRow colSpan={fields.length} message={errors.row} />}
      {modified && (
        <ActionButtons
          colSpan={fields.length}
          onCancelClick={() => setValues(originalValues)}
          onDeleteClick={() => setDeleteConfirmationOpen(true)}
          onSaveClick={() => updateRow.mutate({ id: questionAnswerId, values })}
        />
      )}
      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onFalse={() => setDeleteConfirmationOpen(false)}
        onTrue={() => {
          setDeleteConfirmationOpen(false);
          _delete.mutate(questionAnswerId);
        }}
      />
    </PopulateFieldAnswersProvider>
  );
}
