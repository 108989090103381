import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useEsgOrganization } from 'src/common/hooks';
import QuestionnaireV3 from 'src/QuestionnairesV3';

import {
  createEsgTokenFetcher,
  useEsgQuestionnaireAnswerId,
  useEsgQv3PreviousAnswerHints,
} from './hooks';
import { Box, createTheme } from '@mui/material';
import themeColors from 'src/theme/colors';
import theme from 'src/theme';

export default function EsgQuestionnaires() {
  const params = useParams<{
    formKey: string;
    reportId: string;
  }>();

  const organization = useEsgOrganization();
  const reportId = String(params.reportId);
  const questionnaireKey = String(params.formKey);

  const { data: { questionnaireAnswerId, questionnaireId } = {} } =
    useEsgQuestionnaireAnswerId(reportId, organization.id, questionnaireKey);

  const tokenFetcher = useMemo(
    () =>
      createEsgTokenFetcher(organization.id, reportId, questionnaireAnswerId),
    [organization.id, reportId, questionnaireAnswerId]
  );

  const { hints } = useEsgQv3PreviousAnswerHints(questionnaireAnswerId);

  return (
    <Box width="100%" mt="10px" p="0 64px" mb="80px">
      <QuestionnaireV3
        questionnaireId={questionnaireId}
        questionnaireAnswerId={questionnaireAnswerId}
        tokenFetcher={tokenFetcher}
        options={{
          hints,
          withFieldReferences: true,
          withFieldComments: true,
          withEsgReferences: true,
        }}
        theme={createTheme({
          typography: theme.typography,
          palette: {
            primary: {
              main: themeColors.esgMain,
              contrastText: themeColors.pureWhite,
            },
            background: { default: '#EDF0F1' },
          },
        })}
      />
    </Box>
  );
}
